const initialCollectionColumns = [
  { label: 'Sex:', column: 'sex' },
  { label: 'Collection method:', column: 'collection_method' },
  { label: 'Dataset type:', column: 'type' },
  { label: 'Imaging modality:', column: 'imaging_modality' },
  { label: 'Body part:', column: 'body_part_examined' },
  { label: 'Access type:', column: 'image_access_type' },
  { label: 'Diagnosis:', column: 'diagnosis_available' },
  { label: 'Topography:', column: 'topography' },
  { label: 'Terms of use:', column: 'terms_of_use' },
  { label: 'Image vendor:', column: 'vendor' }
]

module.exports = initialCollectionColumns
