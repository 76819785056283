var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row biobank-explorer-container"},[_c('div',{staticClass:"col-md-3"},[_c('filter-container')],1),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[(_vm.isIE11)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ie11BookmarkToApply),expression:"ie11BookmarkToApply"}],staticClass:"w-50 mr-2 p-1",attrs:{"type":"text","placeholder":"Place your recieved bookmark here"},domProps:{"value":(_vm.ie11BookmarkToApply)},on:{"input":function($event){if($event.target.composing){ return; }_vm.ie11BookmarkToApply=$event.target.value}}}),_c('input',{staticClass:"btn btn-sm btn-secondary",attrs:{"type":"button","value":"Apply","disabled":!_vm.ie11BookmarkToApply},on:{"click":_vm.applyIE11Bookmark}}),_c('div',{staticClass:"mt-1"},[_c('input',{staticClass:"w-50 d-inline p-1",attrs:{"id":"ie11bookmark","placeholder":"Your current bookmark"},domProps:{"value":_vm.ie11Bookmark}}),_c('button',{staticClass:"btn btn-sm btn-success ml-2 d-inline",attrs:{"disabled":!_vm.ie11Bookmark},on:{"click":_vm.copyIE11Bookmark}},[_vm._v(" Copy"),_c('span',{staticClass:"fa fa-copy ml-1"})])])]):_vm._e()]),(!_vm.loading && _vm.foundCollectionIds.length)?_c('collection-select-all',{staticClass:"col-md-4 text-right",attrs:{"bookmark":""}}):_vm._e()],1),_c('div',{staticClass:"row"},[(!_vm.loading)?_c('div',{staticClass:"col-md-12"},[_c('result-header')],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('biobank-cards-container')],1)])]),(
      !_vm.loading &&
      _vm.hasSelection &&
      !_vm.collectionCartShown &&
      this.foundCollectionIds.length
    )?_c('cart-selection-toast',{attrs:{"cartSelectionText":((this.selectedCollections.length) + " dataset(s) selected"),"clickHandler":_vm.showSelection,"title":_vm.negotiatorButtonText,"toastClass":"bg-warning text-white"},scopedSlots:_vm._u([{key:"buttonText",fn:function(){return [_vm._v(" Show selection ")]},proxy:true}],null,false,3848600821)}):_vm._e(),_c('b-modal',{attrs:{"hide-header":"","id":"collectioncart-modal","size":"lg","scrollable":"","centered":"","body-bg-variant":"white","footer-bg-variant":"warning","body-class":"pb-0"},on:{"hide":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn btn-dark mr-auto",on:{"click":_vm.removeAllCollections}},[_vm._v("Remove all")]),_c('div',[_c('span',{staticClass:"text-white font-weight-bold d-block"},[_vm._v(_vm._s(_vm.modalFooterText))]),(_vm.selectedNonCommercialCollections > 0)?_c('span',{staticClass:"text-white"},[_c('font-awesome-icon',{staticClass:"text-white non-commercial mr-1",attrs:{"title":"Not available for commercial use","icon":['fab', 'creative-commons-nc-eu']}}),_vm._v(" "+_vm._s(_vm.selectedNonCommercialCollections)+" are non-commercial only ")],1):_vm._e()]),_c('div',{staticClass:"ml-auto"},[_c('b-button',{staticClass:"btn btn-dark mr-2",on:{"click":_vm.hideModal}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"btn btn-secondary ml-auto",attrs:{"disabled":(_vm.isPodium && !_vm.collectionsInPodium.length) ||
            !_vm.selectedCollections.length},on:{"click":_vm.sendRequest}},[_vm._v(_vm._s(_vm.negotiatorButtonText))])],1)]},proxy:true}])},[(_vm.collectionCart.length > 0)?_vm._l((_vm.collectionCart),function(cart,index){return _c('div',{key:((cart.biobankLabel) + "-" + index),staticClass:"card mb-3 border"},[_c('div',{staticClass:"card-header font-weight-bold"},[_vm._v(_vm._s(cart.biobankLabel))]),_c('div',{staticClass:"collection-cart"},_vm._l((cart.collections),function(collection,index){return _c('div',{key:((collection.label) + "-" + index),staticClass:"card-body d-flex border-bottom"},[_c('div',[(_vm.isNonCommercialCollection(collection.value))?_c('font-awesome-icon',{staticClass:"text-danger non-commercial mr-1",attrs:{"title":"Not available for commercial use","icon":['fab', 'creative-commons-nc-eu']}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(collection.label))])],1),_c('div',{staticClass:"pl-3 ml-auto"},[_c('span',{staticClass:"fa fa-times text-bold remove-collection",attrs:{"title":"Remove collection"},on:{"click":function($event){return _vm.RemoveCollectionsFromSelection({
                    collections: [collection],
                    router: _vm.$router,
                  })}}})])])}),0)])}):_vm._e(),(_vm.isPodium && !_vm.collectionsInPodium.length)?_c('p',[_vm._v(" Sorry, none of the samples are currently in Podium. ")]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }