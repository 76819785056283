<template>
  <div>
    <collection-selector
      class="mb-2"
      v-if="isTopLevelCollection"
      :collectionData="collection"/>

    <report-description
      :description="collection.description"
      :maxLength="500"></report-description>

    <!-- main collection information -->
    <table class="mg-report-details-list mb-3">
      <report-list-row :data="mainContent.Size">Subjects:</report-list-row>
      <report-list-row :data="mainContent.Num_studies">Studies:</report-list-row>
      <report-list-row :data="mainContent.Num_series">Series:</report-list-row>
      <tr v-if="mainContent.Age && mainContent.Age.value">
        <th scope="row" class="pr-1">Age:</th>
        <td>{{ mainContent.Age.value }}</td>
      </tr>
      <create-collection-details
        v-for="(property, prop_index) in properties"
        :key="property.label + prop_index" :property="property"
        :collection="mainContent" :badgeColor="generateBadgeColor()" />
      <tr v-if="collection.age_low ">
        <th v-if="collection.age_median" scope="row" class="pr-1">Age low/med/high:</th>
        <th v-else scope="row" class="pr-1">Age low/high:</th>
        <td v-if="collection.age_median">
          <span>
            {{ collection.age_low  }} / {{ collection.age_median  }} / {{ collection.age_high  }} years
          </span>
        </td>
        <td v-else>
          <span>
            {{ collection.age_low  }} / {{ collection.age_high  }} years
          </span>
        </td>
      </tr>
      <tr v-if="collection.publication_uri ">
        <th scope="row" class="pr-1">Publication:</th>
        <td>
          <span><a target="_blank" :href="collection.publication_uri ">
            {{ collection.publication_uri  }}
          </a></span>
        </td>
      </tr>
      <tr v-if="collection.image_year_range">
        <th scope="row" class="pr-1">Year:</th>
        <td>
          <span>
            {{ collection.image_year_range }}
          </span>
        </td>
      </tr>
      <tr v-if="collection.intended_purpose">
        <th scope="row" class="pr-1">Intended purpose:</th>
        <td>
          <span>
            {{ collection.intended_purpose }}
          </span>
        </td>
      </tr>
      <tr v-if="collection.metadata_issued">
        <th scope="row" class="pr-1">Metadata issued:</th>
        <td>
          <span>
            {{ collection.metadata_issued }}
          </span>
        </td>
      </tr>
      <tr v-if="collection.last_modified">
        <th scope="row" class="pr-1">Last modified:</th>
        <td>
          <span>
            {{ collection.last_modified }}
          </span>
        </td>
      </tr>
      <tr v-if="collection.version">
        <th scope="row" class="pr-1">Version:</th>
        <td>
          <span>
            {{ collection.version }}
          </span>
        </td>
      </tr>
      <tr v-if="collection.provider">
        <th scope="row" class="pr-1">Provider:</th>
        <td>
          <span>
            {{ collection.provider }}
          </span>
        </td>
      </tr>
      <tr v-if="collection.image_size">
        <th scope="row" class="pr-1">Image Size:</th>
        <td>
          <span>
            {{ collection.image_size }} gigabyte(s)
          </span>
        </td>
      </tr>
      <tr v-if="collection.image_access_uri ">
        <th scope="row" class="pr-1">Imaging data:</th>
        <td>
          <span><a target="_blank" :href="collection.image_access_uri ">
            {{ collection.image_access_uri  }}
          </a></span>
        </td>
      </tr>
      <tr v-if="collection.non_image_data_access_uri ">
        <th scope="row" class="pr-1">Non-imaging data:</th>
        <td>
          <span><a target="_blank" :href="collection.non_image_data_access_uri ">
            {{ collection.non_image_data_access_uri  }}
          </a></span>
        </td>
      </tr>
    </table>
    <tr v-if="collection.image_access_description ">
      <b>Data access information: </b>{{collection.image_access_description}}
    </tr>
    <!-- Recursive set of subcollections -->
    <div
      v-if="collection.sub_collections && collection.sub_collections.length"
      class="mt-2">
      <h5>Sub collections</h5>
      <report-sub-collection
        v-for="subCollection in collection.sub_collections"
        :collection="subCollection"
        :key="subCollection.id"
        :level="1"></report-sub-collection>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapCollectionsDetailsTableContent } from '../../utils/templateMapper'
import CollectionSelector from '../buttons/CollectionSelector.vue'
import CreateCollectionDetails from '../generators/CreateCollectionDetails.vue'
import ReportDescription from '../report-components/ReportDescription.vue'
import ReportListRow from '../report-components/ReportListRow.vue'
import ReportSubCollection from '../report-components/ReportSubCollection.vue'
import { createColumnKey } from '../../utils/generatorUtils'

export default {
  name: 'ReportCollectionDetails',
  props: {
    collection: {
      type: Object,
      required: true
    }
  },
  components: {
    CollectionSelector,
    ReportDescription,
    ReportListRow,
    ReportSubCollection,
    CreateCollectionDetails
  },
  computed: {
    ...mapState(['collectionColumns']),
    mainContent () {
      return this.collection
        ? mapCollectionsDetailsTableContent(this.collection)
        : {}
    },
    isTopLevelCollection () {
      return this.collection.parent_collection === undefined
    },
    properties () {
      const collectionKeys = Object.keys(this.mainContent)

      // filter out anything we don't have value for, so we dont mess up the badge-colors
      return this.collectionColumns.filter(prop => collectionKeys
        .includes(createColumnKey(prop.column)) &&
        this.mainContent[createColumnKey(prop.column)].value &&
        this.mainContent[createColumnKey(prop.column)].value.length)
    }
  },
  methods: {
    generateBadgeColor () {
      // const badgeColors = ['info', 'secondary', 'danger', 'primary', 'success']
      const badgeColors = ['info', 'secondary', 'primary', 'success']
      let nextBadgeColor = 0

      if (this.prevBadgeColor === -1) {
        this.prevBadgeColor = 0
      } else {
        // nextBadgeColor = this.prevBadgeColor === 4 ? 0 : this.prevBadgeColor + 1
        nextBadgeColor = this.prevBadgeColor === 3 ? 0 : this.prevBadgeColor + 1
      }
      this.prevBadgeColor = nextBadgeColor

      return badgeColors[nextBadgeColor]
    }
  },
  created () {
    this.prevBadgeColor = -1
  }
}
</script>

<style scoped>
::v-deep .mg-report-details-list th {
  vertical-align: top;
}
</style>
