<template>
  <table class="table table-condensed table-responsive">
    <thead>
      <tr>
        <th class="col-3" scope="col">Dataset</th>
        <th scope="col">Body parts</th>
        <th scope="col">Image modalities</th>
        <th scope="col">#Subjects</th>
        <th class="col-1" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(collection, index) in topLevelElements">
        <tr :key="index">
          <td
            :class="[{
              'table-text-content-columns-has-sub': hasSubCollections(collection),
              'table-text-content-columns': !hasSubCollections(collection)},
              columnSize (column)
            ]"
            v-for="(column, index) in columns"
            :key="index">
            <span v-if="column === 'name'">
              <router-link :to="'/collection/' + collection['id']">
                <button
                  class="btn btn-link collection-link text-left pt-0 border-0 px-0">
                  {{ collection[column] }}
                </button>
              </router-link>
            </span>
            <span v-else-if="column === 'type'">
              {{ getCollectionType(collection) }}
            </span>
            <span v-else-if="column === 'body_part_examined'">
              {{ getCollectionBodyPart(collection) }}
            </span>
            <span v-else-if="column === 'imaging_modality'">
              {{ getCollectionImagingModality(collection) }}
            </span>
            <span v-else-if="column === 'size'">
              {{ getCollectionSize(collection) }}
            </span>
          </td>
        </tr>
        <tr v-if="hasSubCollections(collection)" :key="collection.id">
          <td colspan="5" class="sub-table-cell">
            <b-link v-b-toggle="'collapse-' + collection.id" class="text-muted">
              <span class="when-hidden">
                Show
                {{ collection.sub_collections.length }} subcollections
                <i class="fa fa-caret-down"></i>
              </span>
              <span class="when-visible">
                Hide subcollections
                <i class="fa fa-caret-up"></i>
              </span>
            </b-link>
            <b-collapse :id="'collapse-' + collection.id">
              <sub-collections-table
                :subCollections="collection.sub_collections"></sub-collections-table>
            </b-collapse>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import utils from '../../utils'
import SubCollectionsTable from './SubCollectionsTable'
// import CollectionSelector from '../buttons/CollectionSelector'

export default {
  name: 'CollectionsTable',
  components: {
    SubCollectionsTable
    // CollectionSelector
  },
  props: {
    collections: {
      type: Array,
      required: true
    },
    isSubCollectionsTableVisible: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    parentCollections () {
      return this.topLevelElements.map((tle) => ({
        label: tle.label || tle.name,
        value: tle.id
      }))
    },
    topLevelElements () {
      return this.collections.filter(
        (collection) => !collection.parent_collection
      )
    }
  },
  data () {
    return {
      columns: ['name', 'body_part_examined', 'imaging_modality', 'size']
    }
  },
  methods: {
    getCollectionType (collection) {
      return utils
        .getUniqueIdArray(collection.type.map((type) => type.label))
        .join(', ')
    },
    getCollectionBodyPart (collection) {
      return utils
        .getUniqueIdArray(collection.body_part_examined.map((bodyPart) => bodyPart.label))
        .join(', ')
    },
    getCollectionImagingModality (collection) {
      return utils
        .getUniqueIdArray(collection.imaging_modality.map((ImagingModality) => ImagingModality.label))
        .join(', ')
    },
    hasSubCollections (collection) {
      return (
        collection &&
        collection.sub_collections &&
        collection.sub_collections.length > 0
      )
    },
    getCollectionSize (collection) {
      return collection.size || collection.order_of_magnitude.size
    },
    columnSize (column) {
      return column === 'name' ? 'col-3' : 'col-2'
    }
  }
}
</script>

<style>
.collapsed > .when-visible {
  display: none;
}

:not(.collapsed) > .when-hidden {
  display: none;
}

.table-text-content-columns {
  font-size: 13px;
  font-weight: bold;
}

.table-text-content-columns-has-sub {
  font-size: 13px;
  font-weight: bold;
  border-style: hidden;
  border-width: 0px;
}

.sub-table-cell {
  padding-top: 0px;
}

.collection-link {
  white-space: normal !important;
  line-height: normal;
}
</style>
