/* istanbul ignore file */
import { genericFilterOptions, ontologyFilterOptions } from './filterOptions'

/*
How to add a filter:
- Note: You can only add a filter for options in a table.
- 'statisfyAll' is the option that changes the query from an 'or' to an 'and'.
   So checking Male and Female without 'satisfy all' means 'all datasets with either Male or Female', with 'statisfy all' is 'all datasets with both Male and Female'.
   Check if adding the satisfy all checkbox makes sense for the attribute.
- In this file, filterDefinitions.js, add a dictionary like this:
  {
    component: 'CheckboxFilter',
    name: 'sex', // Filter name, can be arbitrary, but has to match the other options
    label: 'Sex',
    type: 'checkbox-filter',
    table: 'EUCAIM_sex', // Table out of the data model
    options: genericFilterOptions('EUCAIM_sex', 'sex'), // Arguments are (table from data model, filter name)
    initiallyCollapsed: !state.route.query.sex, // !state.route.query.filter_name
    filters: state.filters.selections.sex, // state.filters.selections.filter_name
    satisfyAll: state.filters.satisfyAll.includes('sex'), // state.filters.satisfyAll.includes(filter_name)
    showSatisfyAllCheckbox: true, // Remove if not necessary
    maxVisibleOptions: 25, // Amount of options shown before clicking 'Show more'
    humanReadableString: 'Sex:'
  }
- After that add a query to /src/store/helpers/index.js under CreateRSQLQuery
  With 'satisfyAll':
    createQuery(state.filters.selections.sex, 'sex', state.filters.satisfyAll.includes('sex')),
  which is set up as
    createQuery(see 'filters' from dictionary above, column_name from the collection table, see 'satisfyAll' in dictionary)
  The function createQuery will fall back to createInQuery if 'satisfyAll' is not checked.
  Without 'satisfyAll':
    createInQuery('network', state.filters.selections.network || []),
  which is set up as:
    createInQuery(column_name, see 'filters' from dictionary || []),
*/
const filterDefinitions = (state) => [
  {
    name: 'search',
    label: 'Search',
    type: 'string-filter',
    humanReadableString: 'Text search is'
  },
  {
    component: 'CheckboxFilter',
    name: 'network',
    label: 'Providers',
    type: 'checkbox-filter',
    table: 'EUCAIM_networks',
    options: genericFilterOptions('EUCAIM_networks', 'network'),
    initiallyCollapsed: !state.route.query.network,
    filters: state.filters.selections.network,
    maxVisibleOptions: 25,
    humanReadableString: 'Providers:'
  },
  // {
  //   component: 'CheckboxFilter',
  //   name: 'network',
  //   label: 'Networks',
  //   type: 'checkbox-filter',
  //   table: 'EUCAIM_networks',
  //   options: genericFilterOptions('EUCAIM_networks', 'network'),
  //   initiallyCollapsed: !state.route.query.network,
  //   filters: state.filters.selections.network,
  //   maxVisibleOptions: 25,
  //   humanReadableString: 'Networks:'
  // },
  // {
  //   component: 'CheckboxFilter',
  //   name: 'country',
  //   label: 'Countries',
  //   type: 'checkbox-filter',
  //   table: 'EUCAIM_countries',
  //   options: genericFilterOptions('EUCAIM_countries', 'country'),
  //   initiallyCollapsed: !state.route.query.country,
  //   filters: state.filters.selections.country,
  //   maxVisibleOptions: 25,
  //   humanReadableString: 'Countries:'
  // },
  {
    component: 'CheckboxFilter',
    name: 'body_parts',
    label: 'Body parts',
    type: 'checkbox-filter',
    table: 'EUCAIM_body_parts',
    options: ontologyFilterOptions('EUCAIM_body_parts', 'body_parts'),
    initiallyCollapsed: !state.route.query.bodyPart,
    filters: state.filters.selections.bodyPart,
    satisfyAll: state.filters.satisfyAll.includes('body_parts'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 10,
    humanReadableString: 'Body part(s):'
  },
  {
    component: 'CheckboxFilter',
    name: 'imaging_modality',
    label: 'Imaging modalities',
    type: 'checkbox-filter',
    table: 'EUCAIM_imaging_modality',
    options: ontologyFilterOptions('EUCAIM_imaging_modality', 'imaging_modality'),
    initiallyCollapsed: !state.route.query.imaging_modality,
    filters: state.filters.selections.imaging_modality,
    satisfyAll: state.filters.satisfyAll.includes('imaging_modality'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Image modality:'
  },
  {
    component: 'CheckboxFilter',
    name: 'type',
    label: 'Collection methods',
    type: 'checkbox-filter',
    table: 'EUCAIM_collection_types',
    options: genericFilterOptions('EUCAIM_collection_types', 'type'),
    initiallyCollapsed: !state.route.query.type,
    filters: state.filters.selections.type,
    satisfyAll: state.filters.satisfyAll.includes('type'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Collection method(s):'
  },
  {
    component: 'CheckboxFilter',
    name: 'dataType',
    label: 'Dataset types',
    type: 'checkbox-filter',
    table: 'EUCAIM_data_types',
    options: genericFilterOptions('EUCAIM_data_types', 'dataType'),
    initiallyCollapsed: !state.route.query.dataType,
    filters: state.filters.selections.dataType,
    satisfyAll: state.filters.satisfyAll.includes('dataType'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Data type(s):'
  },
  {
    component: 'CheckboxFilter',
    name: 'image_access_type',
    label: 'Image access types',
    type: 'checkbox-filter',
    table: 'EUCAIM_image_access_type',
    options: genericFilterOptions('EUCAIM_image_access_type', 'image_access_type'),
    initiallyCollapsed: !state.route.query.image_access_type,
    filters: state.filters.selections.image_access_type,
    // satisfyAll: state.filters.satisfyAll.includes('image_access_type'),
    // showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Image Access type:'
  },
  {
    component: 'CheckboxFilter',
    name: 'sex',
    label: 'Sex',
    type: 'checkbox-filter',
    table: 'EUCAIM_sex',
    options: ontologyFilterOptions('EUCAIM_sex', 'sex'),
    initiallyCollapsed: !state.route.query.sex,
    filters: state.filters.selections.sex,
    satisfyAll: state.filters.satisfyAll.includes('sex'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Sex:'
  },
  {
    component: 'CheckboxFilter',
    name: 'vendor',
    label: 'Vendor',
    type: 'checkbox-filter',
    table: 'EUCAIM_vendor',
    options: genericFilterOptions('EUCAIM_vendor', 'vendor'),
    initiallyCollapsed: !state.route.query.vendor,
    filters: state.filters.selections.vendor,
    satisfyAll: state.filters.satisfyAll.includes('vendor'),
    showSatisfyAllCheckbox: true,
    maxVisibleOptions: 25,
    humanReadableString: 'Vendor:'
  }
  // {
  //   component: 'CheckboxFilter',
  //   name: 'supporting_data',
  //   label: 'Supporting data',
  //   type: 'checkbox-filter',
  //   table: 'EUCAIM_supporting_data',
  //   options: genericFilterOptions('EUCAIM_supporting_data', 'supporting_data'),
  //   initiallyCollapsed: !state.route.query.supporting_data,
  //   filters: state.filters.selections.supporting_data,
  //   satisfyAll: state.filters.satisfyAll.includes('supporting_data'),
  //   showSatisfyAllCheckbox: true,
  //   maxVisibleOptions: 25,
  //   humanReadableString: 'Supporting data:'
  // }
  // {
  //   component: 'CheckboxFilter',
  //   name: 'imaging format',
  //   label: 'Image format',
  //   type: 'checkbox-filter',
  //   table: 'EUCAIM_imaging_format',
  //   options: genericFilterOptions('EUCAIM_imaging_format', 'imaging_format'),
  //   initiallyCollapsed: !state.route.query.imaging_format,
  //   filters: state.filters.selections.imaging_format,
  //   satisfyAll: state.filters.satisfyAll.includes('imaging_format'),
  //   showSatisfyAllCheckbox: true,
  //   maxVisibleOptions: 25,
  //   humanReadableString: 'Image Format:'
  // }
]

export default filterDefinitions
