<template>
  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <div class="card-text">
          <!--
          <template v-if="info.contact && info.head != 'No Information'">
            <h5>Contact Information</h5>
            <ul class="right-content-list">
              <template v-if="info.head">
                <li>
                  <span class="font-weight-bold mr-1">Head/PI:</span>
                  <span>{{ info.head }}</span>
                </li>
              </template>
              <li v-if="info.contact.name">
                <span class="font-weight-bold mr-1">Contact:</span>
                <span>{{ info.contact.name }}</span>
              </li>
              <li v-if="info.contact.email">
                <span class="fa fa-fw fa-paper-plane mr-2" aria-hidden="true"></span>
                <a :href="'mailto:' + info.contact.email">
                  <span>Email</span>
                </a>
              </li>
            </ul>
          </template>
        -->
          <template v-if="info.biobank">
            <h5>Dataset(s)</h5>
            <ul class="right-content-list">
              <li>
                <div>
                  {{ info.biobank.name }}
                </div>
                <!--
                <div v-if="info.biobank.juridical_person != 'unknown'">
                  {{ info.biobank.juridical_person }}
                </div>
                -->
                <div>
                  {{ info.biobank.country }}
                </div>
              </li>
              <li>
                <div v-if="info.biobank.report">
                  <span class="fa fa-fw fa-address-card mr-2" aria-hidden="true"></span>
                  <router-link :to="info.biobank.report">
                    <span>View {{ info.biobank.name }}</span>
                  </router-link>
                </div>
                <div v-if="info.biobank.website">
                  <span class="fa fa-fw fa-globe mr-2" aria-hidden="true"></span>
                  <a :href="info.biobank.website" target="_blank" rel="noopener noreferrer">
                    <span>Website</span>
                  </a>
                </div>
                <!--
                <div v-if="info.biobank.email && info.biobank.email != 'noinfo@email.com'">
                  <span class="fa fa-fw fa-paper-plane mr-2" aria-hidden="true"></span>
                  <a :href="'mailto:' + info.biobank.email">
                    <span>Email</span>
                  </a>
                </div>
              -->
              </li>
            </ul>
          </template>
          <template v-if="info.networks && info.networks.length > 0">
            <h5>Provider(s)</h5>
            <ul class="right-content-list">
              <li>
                <div class="info-list" v-for="(network, index) in info.networks" :key="`${network.name}-${index}`">
                  <span class="font-weight-bold mr-2">Name:</span>
                  <span>{{ network.name }}</span>
                  <div>
                    <span class="fa fa-fw fa-address-card mr-2" aria-hidden="true"></span>
                    <router-link :to="network.report">
                      <span>View {{ network.name }} network</span>
                    </router-link>
                  </div>
                </div>
              </li>
            </ul>
          </template>
          <template v-if="info.collaboration.length > 0">
            <h5>Collaboration</h5>
            <div class="container p-0">
              <div class="row" v-for="(collab, index) in info.collaboration" :key="`${collab.name}-${index}`">
                <div class="col pr-0">
                  <span class="font-weight-bold">{{ collab.name }}:</span>
                </div>
                <div class="col p-0">
                  <span class="badge badge-info">{{ collab.value }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollectionReportInfoCard',
  props: {
    info: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.biobank-id {
  word-break: break-all;
}

.right-content-list {
  list-style-type: none;
  margin-left: -2.5rem;
}
.right-content-list:not(:last-child) {
  margin-bottom: 1.5rem;
}

.right-content-list li {
  margin-bottom: 0.5rem;
}

.info-list {
  margin-bottom: 1rem;
}

.cert-badge:not(:last-child) {
  margin-right: 1rem;
}
</style>
