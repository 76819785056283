<template>
  <div class="mg-biobank-card container">
    <loading
      :active="isLoading"
      loader="dots"
      color="var(--secondary)"
      background-color="var(--light)"></loading>
      <div class="container-fluid">
      <div class="row">
        <div class="col my-3 shadow-sm d-flex p-2 align-items-center">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb my-1">
              <li class="breadcrumb-item">
                <router-link to="/" title="Back to the catalogue">
                  Home
                </router-link>
              </li>
              <li class="breadcrumb-item active text-dark" aria-current="page">
                {{ biobank.name }}
              </li>
            </ol>
          </nav>
          <check-out
            class="ml-auto"
            :disabled="biobank.withdrawn"
            :bookmark="false"/>
        </div>
      </div>

      <div class="row" v-if="biobankDataAvailable && !this.isLoading">
        <div class="col">
          <report-title type="Dataset" :name="biobank.name"></report-title>
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <p><b>Id: </b>{{ biobank.id }}</p>
                <report-description
                  :description="biobank.description"
                  :maxLength="500"></report-description>
                <!-- <h3>Dataset</h3> -->
                <div
                  v-for="(collection, index) in collectionsData"
                  :key="collection.id">
                  <hr v-if="index" />
                  <collection-title
                    :title="collection.name"
                    :id="collection.id"/>
                  <!-- <collection-selector
                    :disabled="biobank.withdrawn"
                    class="pl-4 ml-auto"
                    :collectionData="collection"/> -->
                  <report-collection-details
                    :collection="collection"></report-collection-details>
                </div>
              </div>
              <!-- Right side card -->
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body">
                    <div class="card-text">
                      <!--
                      <h5>Contact Information</h5>
                      <report-details-list
                        :reportDetails="contact"></report-details-list>
                      -->
                      <h5 v-if="networks && networks.length > 0">Provider(s)</h5>
                      <report-details-list
                        :reportDetails="network"
                        v-for="network in networks"
                        :key="network.id"></report-details-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import ReportDescription from '../components/report-components/ReportDescription.vue'
import ReportTitle from '../components/report-components/ReportTitle.vue'
import ReportDetailsList from '../components/report-components/ReportDetailsList.vue'
import CollectionTitle from '../components/report-components/CollectionTitle.vue'
import ReportCollectionDetails from '../components/report-components/ReportCollectionDetails.vue'
// import CollectionSelector from '../components/buttons/CollectionSelector.vue'
import CheckOut from '../components/checkout/CheckOut.vue'

import {
  mapCollectionsDetailsTableContent,
  mapContactInfo,
  mapNetworkInfo
} from '../utils/templateMapper'

import { mapBiobankToBioschemas } from '@/utils/bioschemasMapper'

export default {
  name: 'biobank-report-card',
  components: {
    ReportTitle,
    ReportDescription,
    ReportDetailsList,
    Loading,
    ReportCollectionDetails,
    CollectionTitle,
    // CollectionSelector,
    CheckOut
  },
  data () {
    return {
      collapsed: true
    }
  },
  computed: {
    ...mapState({
      biobank: 'biobankReport',
      isLoading: 'isLoading'
    }),
    biobankDataAvailable () {
      return this.biobank && this.biobank
    },
    query () {
      return this.$route.query
    },
    networks () {
      return this.biobankDataAvailable && this.biobank.network
        ? mapNetworkInfo(this.biobank)
        : []
    },
    contact () {
      return this.biobankDataAvailable && this.biobank.contact
        ? mapContactInfo(this.biobank)
        : {}
    },
    collectionsData () {
      return this.biobankDataAvailable && this.biobank.collections
        ? this.biobank.collections
          .filter((it) => !it.parent_collection)
          .map((col) => mapCollectionsDetailsTableContent(col))
        : []
    },
    bioschemasJsonld () {
      return this.biobankDataAvailable
        ? mapBiobankToBioschemas(this.biobank)
        : {}
    }
  },
  methods: {
    ...mapActions(['GetBiobankReport']),
    back () {
      this.$router.go(-1)
    }
  },
  mounted () {
    this.GetBiobankReport(this.$store.state.route.params.id)
  }
}
</script>
